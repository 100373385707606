// General shared styles used alongside the project (B stands for the Blue Design).
.b-container {
  padding-right: 20px;
  padding-left: 49px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1200px) {
    padding-left: 82px;
    padding-right: 60px;
  }

  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.b-page {
  p {
    font-size: 16px;
    line-height: 25px;
    font-weight: normal;
    color: var(--color-gray4);
  }
}

.b-page-wrapper--gray {
  background-color: #f8f8f8;
}

.b-page-title {
  margin-top: 85px;
  margin-bottom: 0;
  font-family: 'Fira Sans', sans-serif;
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  color: var(--color-gray4);

  @media (max-width: 768px) {
    margin-top: 70px;
    font-size: 44px;
    line-height: 52px;
  }

  @media (max-width: 576px) {
    text-align: start;
  }
}

.b-page-subtitle {
  margin-bottom: 0;
  font-family: 'Fira Sans', sans-serif;
  font-size: 26px;
  line-height: 34px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  color: var(--color-blue);

  @media (max-width: 576px) {
    text-align: start;
  }
}

.b-button {
  padding: 11px 10px;
  min-width: 186px;
  display: inline-block;

  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;

  background-color: var(--color-blue);
  border-radius: 4px;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.b-button:hover {
  text-decoration: none;
  background-color: #00139b;
}

.b-button--light {
  background-color: #ffffff;
  color: var(--color-blue);
  border: 2px solid #2e4fd7;;
}

.b-button--light:hover {
  background-color: #ffffff;
  color: var(--color-blue);
  border: 2px solid #2e4fd7;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.08);
}

.b-auth__header {
  margin-bottom: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.b-auth__logo {
  height: 27px;

  img {
    width: 136px;
  }
}

.b-auth__header-link {
  display: none;

  a {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--color-gray4);
  }
}

.b-auth__title {
  margin-bottom: 40px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
  color: var(--color-gray4);
}

.b-auth__buttons {
  .b-button {
    width: 100%;
  }
}

.b-auth__error {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 350;
  line-height: 1.57;
  letter-spacing: 0.42px;
  text-align: center;
  color: #f00;
}

.b-auth__google-button {
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  color: #495057;

  border: 1px solid var(--color-gray3);
  background-color: #ffffff;

  img {
    margin-right: 24px;
    vertical-align: top;
  }

  &:hover {
    background-color: #ffffff;
  }
}

@media (max-width: 576px) {
  .b-auth__header-link {
    display: block;
  }

  .b-auth__logo {
    img {
      width: 140px;
    }
  }

  .b-auth__title {
    margin-bottom: 34px;
  }

  .b-auth__buttons {
    padding: 0 8px;
  }

  .b-auth__google-button {
    margin-top: 15px;
  }
}

.b-toggle {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  width: 32px;
  height: 16px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .b-toggle__slider {
    background-color: var(--color-blue);

    &::before {
      transform: translateX(15px);
      background-color: #ffffff;
    }
  }

  input:focus + .b-toggle__slider {
    box-shadow: 0 0 1px var(--color-blue);
  }
}

.b-toggle__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid var(--color-blue);
  background-color: #ffffff;
  border-radius: 37px;
  transition: 0.4s;
}

.b-toggle__slider::before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 1px;
  border-radius: 50%;
  background-color: var(--color-blue);
  transition: 0.4s;
}

.b-static-flow-page__title-wrapper {
  .b-static-flow-page__price {
    font-size: 22px;
    line-height: 1.36;
    display: none;
  }
}

.b-static-flow-page__container {
  margin: 55px auto 85px;
  padding: 58px 47px 40px;
  max-width: 980px;
  display: flex;
  border-radius: 8px;
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.08);
}

.b-static-flow-page__image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 304px;
  }
}

.b-static-flow-page__info {
  flex: 1;
  margin-left: 20px;
  max-width: 404px;
}

.b-static-flow-page__price {
  margin-bottom: 18px;
  padding-bottom: 13px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 28px;
  line-height: 1.07;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid var(--color-gray2);
  color: var(--color-blue);
}

.b-static-flow-page__text {
  font-size: 16px;
  line-height: 1.56;
  font-weight: 400;
  color: var(--color-gray4);
}

.b-static-flow-page__mobile-text-wrapper {
  display: none;
  margin-bottom: 20px;
}

.b-static-flow-page__list {
  margin-top: 30px;
  margin-bottom: 40px;
  padding-inline-start: 35px;

  li {
    margin: 24px 0;
    font-size: 16px;
    line-height: 1.44;
    font-weight: 400;
    color: var(--color-gray4);
  }

  strong {
    font-weight: 600;
  }
}

.b-static-flow-page__button {
  width: 100%;
}

@media (max-width: 576px) {
  .b-static-flow-page__title-wrapper {
    margin-top: 70px;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-gray2);

    .b-page-title {
      margin-top: 0;
      font-size: 22px;
      line-height: 1.09;
    }

    .b-static-flow-page__price {
      display: block;
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .b-static-flow-page__container {
    margin: 35px auto 50px;
    padding: 0;
    flex-direction: column;
    border-radius: 0;
    box-shadow: none;
  }

  .b-static-flow-page__mobile-text-wrapper {
    display: block;
  }

  .b-static-flow-page__info {
    margin-left: 0;
    max-width: none;

    .b-static-flow-page__price, .b-static-flow-page__text {
      display: none;
    }
  }

  .b-static-flow-page__list {
    margin-top: 40px;
    margin-bottom: 30px;
    padding-inline-start: 25px;
  }
}

.b-dashboard-container {
  padding-right: 38px;
  padding-left: 38px;
  padding-top: 10px;

  @media (max-width: 960px) {
    padding-right: 17px;
    padding-left: 14px;
  }
}

.dashboard-header-menu-panel {
  max-width: none !important;

  @media (max-width: 960px) {
    position: relative;
    top: -37px;
    left: 16px;
  }

  @media (max-width: 576px) {
    left: 0;
    min-width: 100vw !important;
  }
}

.dashboard-action-menu-panel {
  position: relative;
  top: 46px;
  max-width: 300px !important;
  border: 2px solid var(--color-blue);
  box-shadow: none;
}

.dashboard-action-menu-panel__disable-top {
  top: inherit;
}

.dashboard-notifications-menu-panel {
  max-width: 375px !important;
  position: relative;
  top: -30px;
  left: 2px;

  @media (max-width: 960px) {
    top: -30px;
    left: 0;
  }

  @media (max-width: 576px) {
    top: -40px;
    max-width: 100% !important;
  }
}

.dashboard-mobile-view-menu-panel {
  max-width: 138px !important;
  position: relative;
  border: 2px solid var(--color-blue);
  box-shadow: none;
}

.dashboard-carousel_unavailable {
  pointer-events: none;

  .brain-regions__button {
    background-color: #EFF2FC !important;
    color: #A3B1EC !important;
  }

  .carousel__item--active .brain-regions__button {
    background: #FFFFFF !important;
    color: var(--color-blue) !important;
  }
}

.b-dashboard-insights__container {
  padding-right: 40px;

  @media (max-width: 960px) {
    padding-right: 16px;
    padding-bottom: 50px;
  }
}

.b-dashboard-insights__back-button {
  margin-bottom: 30px;

  a {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #2B3357;
    text-decoration: none;
  }

  img {
    width: 8px;
    margin-right: 20px;
    padding-bottom: 4px;
  }

  @media (max-width: 960px) {
    margin-top: 18px;
    position: relative;
    text-align: center;

    a {
      font-family: 'Fira Sans', sans-serif;
      font-size: 26px;
      line-height: 34px;
      font-weight: 600;
      color: var(--color-gray4);
    }

    img {
      padding: 10px 10px 10px 0;
      width: 18px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.b-dashboard-insights__heading {
  margin-bottom: 12px;
  font-size: 17px;
  line-height: 1.3;
  font-weight: 500;
  color: #2f2e41;
  vertical-align: bottom;
}

.b-dashboard-insights__text {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: var(--color-gray4);

  @media (max-width: 960px) {
    font-size: 15px;
    line-height: 20px;
  }
}

.b-dashboard-insights__show-more {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: var(--color-purple);
  cursor: pointer;

  @media (max-width: 960px) {
    font-size: 15px;
    line-height: 20px;
  }
}

.b-dashboard-insights__age-sex-form {
  max-width: 500px;
}

.scrollable_parent {
  height: 100%;
}

.component_scrollable {
  overflow: auto;

  @media (max-width: 960px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding-bottom: 53px;
    z-index: 101;
    background-color: #ffffff;
  }
}


.b-flow-component {
  height: 100%;
  padding-bottom: 0;

  @media (max-width: 960px) {
    position: absolute;
    top: 0;
    left: 0;
    // height: 100vh;
    width: 100vw;
    padding-bottom: 53px;
    z-index: 101;
    background-color: #ffffff;
  }
}

.b-flow-header {
  @media (min-width: 961px) {
    display: none;
  }
}

.b-flow-content {
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 9px;
    background: var(--color-gray2);
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4D69DD;
  }
}

.b-flow__flow-name {
  margin-top: 50px;
  margin-bottom: 38px;
  padding-bottom: 7px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #2B3357;
  border-bottom: 1px solid rgba(208, 206, 206, 0.55);

  a {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-decoration: none;
    color: var(--color-gray4);
  }

  img {
    width: 8px;
    margin-right: 16px;
    padding-bottom: 4px;
  }
}

.b-flow__back-link {
  margin-left: 55px;
}

.b-flow__container {
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  background: #ffffff;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.b-flow__general-container {
  padding: 36px 47px 28px 49px;

  .b-flow__title {
    margin-bottom: 40px;
  }
}

.b-flow__steps-container {
  margin-bottom: 110px;
  padding: 40px 100px 25px;

  .b-flow__title {
    display: none;
  }
}

.b-flow__success-container {
  margin-top: 70px;
  padding: 70px 34px 25px 67px;

  .b-flow__title {
    margin-bottom: 40px;
  }
}

.b-flow__title {
  margin-bottom: 10px;
  font-family: 'Fira Sans', sans-serif;
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  color: var(--color-gray4);
}

.b-flow__text {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: var(--color-gray4);

  a {
    color: var(--color-gray4);
    font-weight: 600;
  }
}

.b-flow-features-list {
  margin-top: 65px;
  margin-bottom: 40px;
  padding-inline-start: 0;
  list-style: none;

  li {
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.b-flow-features-list__image {
  width: 13px;
  margin-right: 17px;
}

.b-flow-features-list__image--mt1 {
  margin-top: -1px;
}

.b-flow-features-list__text {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: var(--color-gray4);

  strong {
    font-weight: 600;
  }
}

.b-flow__next-button_link-like {
  border: none;
  background-color: inherit;
}

.b-flow__next-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  span {
    padding-right: 14px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: var(--color-blue);
  }
}

.b-flow__header {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: var(--color-gray4);
}

.b-flow__button {
  width: 100%;
}

.b-flow__success-image {
  margin-top: 35px;
  margin-bottom: 125px;
  height: 165px;
  text-align: center;
}

@media (max-width: 959px) {
  .b-flow__flow-name {
    display: none;
  }
}

@media (max-width: 576px) {
  .b-flow__flow-name {
    display: none;
  }

  .b-flow__container {
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 0;
  }

  .b-flow__general-container {
    padding: 70px 0 30px;

    .b-flow__title {
      margin-bottom: 10px;
    }
  }

  .b-flow__success-container {
    padding: 0 0 30px;

    .b-flow__title {
      margin-bottom: 10px;
    }
  }

  .b-flow-features-list {
    margin-top: 30px;
    margin-bottom: 70px;
  }

  .b-flow__steps-container {
    margin-bottom: 0;
    padding: 16px 0 25px;

    .b-flow__title {
      display: block;
      margin-bottom: 15px;
      text-align: center;
    }
  }
}

.b-profile {
  margin-top: 39px;
  padding-bottom: 20px;
}

.b-profile__container {
  margin: 110px auto 50px;
  padding: 27px;
  max-width: 830px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.b-profile__back {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 7px;
    margin-right: 24px;
  }

  span {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: #2B3357;
  }
}

.b-profile__content {
  margin: 30px auto 30px;
  max-width: 550px;
}

.b-profile__table {
  td {
    padding: 5px 0;
  }

  @media (min-width: 576px) {
    tr td:last-of-type {
      width: 60%;
    }
  }
}

.b-profile__table-label {
  font-size: 13px;
  line-height: 57px;
  font-weight: normal;
  text-transform: uppercase;
  color: #747582;
}

.b-profile__form-buttons {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;

  .b-button {
    min-width: 178px;
  }
}

.b-profile__cancel-button {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: var(--color-blue);
  background-color: transparent;
  border: none;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
}


@media (max-width: 576px) {
  .b-profile {
    padding-bottom: 50px;
  }

  .b-profile__container {
    margin: 40px auto 25px;
  }

  .b-profile__table-label {
    display: none;
  }

  .b-profile__form-buttons .b-button {
    min-width: 135px;
  }
}

.b-profile-image-dialog {
  overflow: auto;
  width: 90vw;
  height: 90vh;

  .mat-dialog-container {
    border-radius: 10px;
    padding: 0;
    box-shadow: none;
  }
}

.b-profile-two-factor-dialog {
  overflow: auto;
  width: 90vw;
  height: 90vh;
  max-width: 650px !important;
  max-height: 600px;

  .mat-dialog-container {
    border-radius: 10px;
    padding: 0;
    box-shadow: none;
  }
}

.b-profile__sharing-table {
  width: 100%;

  th {
    padding: 12px 7px 20px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    text-transform: uppercase;
    color: #747582;

    &:last-child {
      width: 50px;
    }
  }
}

.b-profile__sharing-table-row {
  td {
    padding: 12px 7px 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    color: var(--color-gray4);

    &:last-child {
      text-align: end;
    }
  }
}

.b-dashboard-print-brain-dialog {
  overflow: auto;
  width: 90vw;
  max-width: 650px !important;
  max-height: 600px;

  .mat-dialog-container {
    border-radius: 10px;
    padding: 0;
    box-shadow: none;
  }
}
