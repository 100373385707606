.patient-data_id {
  width: 80px;
}

.patient-data_name {
  width: 170px;
}

.patient-data_scan-date {
  width: 170px;
}

.patient-data_expand {
  width: 24px;
}

.patient-data_results {
  width: 100px;
}

.patient-data_email {
  width: 75px;
}

.patient-data_status {
  width: 60px;
}

.patient-data_birth-date {
  width: 115px;
}

.patient-data_access {
  width: 70px;
}

.patient-data_actions {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}


@media screen and (max-width: 1189px) {
  .patient-data_id,
  .patient-data_name,
  .patient-data_scan-date,
  .patient-data_results,
  .patient-data_status,
  .patient-data_email,
  .patient-data_birth-date,
  .patient-data_access,
  .patient-data_actions {
    width: 100%;
  }

  .patient-data_actions {
    max-width: 100%;
    min-width: 100%;
  }

  .patient-data_expand {
    display: none;
  }
}
